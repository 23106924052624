.spin-forward {
    animation-name: spin-forward;
    animation-duration: 4000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.spin-backward {
    animation-name: spin-backward;
    animation-duration: 4000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes spin-forward {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}

@keyframes spin-backward {
    from {transform:rotate(0deg);}
    to {transform:rotate(-360deg);}
}