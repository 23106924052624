#pdfobject {
    height: 100%;
}


#pdf-canvas:not(:first-child) #pdf-canvas:not(:last-child) {
  margin: 10px auto;
}

.page {
  margin: 10px auto;
}


.page > div > canvas {
  border: 1px solid black;
}

#pdf-canvas::-webkit-scrollbar { width: 0 !important }
#pdf-canvas { overflow: -moz-scrollbars-none; }
#pdf-canvas { -ms-overflow-style: none; }

.page ::selection {
  background-color: green;
}

.textLayer { 
  position: absolute;
  overflow: hidden;
  opacity: 0.2;
  line-height: 1.0;
  margin: auto;
}

.page {
  position: relative
}

#pdf-canvas {
    height: calc(100% - 32px);
    top: 32px;
    /* width: 90%; */
    background-color: lightgray;
    padding: 2px;
}



.canvasWrapper {
  position: absolute
}

.textLayer > div {
  color: transparent;
  position: absolute;
  white-space: pre;
  cursor: text;
  transform-origin: 0% 0%;
}
.textLayer > span {
  color: transparent;
  position: absolute;
  white-space: pre;
  cursor: text;
  transform-origin: 0% 0%;
}